<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <div class="bordered pad">
          <h1>Edit profile for {{ user.first_name }} {{ user.last_name }}</h1>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
          <form class="user-form">
            <div class="grid-x grid-padding-x">
              <div class="cell small-12 medium-6">
                <label>Email address</label>
                <input type="text" v-model="user.email_address">
                <label>
                  <input type="checkbox" v-model="user.notifications">
                  Receive email notifications?
                </label>
                <label>Password (leave blank for no change)</label>
                <input type="password" v-model="user.changePassword">
                <label>Re-enter Password</label>
                <input type="password" v-model="user.passwordCheck">
                <label>Forename</label>
                <input type="text" v-model="user.first_name">
                <label>Surname</label>
                <input type="text" v-model="user.last_name">
                <label>Notes</label>
                <vue-editor
                  id="notesEditor"
                  v-bind:editorToolbar="customToolbar"
                  v-model="user.notes"
                  placeholder="Notes text"
                  rows="12">
                </vue-editor>
              </div>
              <div class="cell small-12 medium-6">
                <label>File name (1) <i>For internal use only</i></label>
                <input type="text" v-model="user.fileOneName">
                <div class="file-one"></div>
                <div><p>{{ filenameOne }}</p></div>
                <label>File name (2) <i>For internal use only</i></label>
                <input type="text" v-model="user.fileTwoName">
                <div class="file-two"></div>
                <div><p>{{ filenameTwo }}</p></div>
                <label>File name (3) <i>For internal use only</i></label>
                <input type="text" v-model="user.fileThreeName">
                <div class="file-three"></div>
                <div><p>{{ filenameThree }}</p></div>
                <label>Address</label>
                <textarea v-model="user.address" rows="5"></textarea>
                <button class="button">Save changes</button>
                <div class="UppyProgressBar"></div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      v-if="message.length > 0"
      class="message-wrap">
      <span class="close-message" v-on:click="clearMessage">X</span>
      <p class="message-text">{{ message }}</p>
    </div>
  </div>
</template>

<script>
import axios from '../axios';
import '@uppy/core/dist/style.css';
import '@uppy/file-input/dist/style.css';
import '@uppy/progress-bar/dist/style.css';

const Uppy = require('@uppy/core');
const UppyForm = require('@uppy/form');
const Transloadit = require('@uppy/transloadit');
const ProgressBar = require('@uppy/progress-bar');
const FileInput = require('@uppy/file-input');

export default {
  name: 'EditProfile',
  data() {
    return {
      tutorGroups: [],
      user: {
        first_name: '',
        last_name: '',
        email_address: '',
        password: '',
        userlevel: 0,
        notes: '',
        fileOneName: '',
        fileTwoName: '',
        fileThreeName: '',
        tutorGroup: 0,
      },
      filenameOne: '',
      filenameTwo: '',
      filenameThree: '',
      message: '',
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ header: '1' }, { header: '2' }],
        ['link'],
      ],
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
  },
  methods: {
    getUser() {
      // eslint-disable-next-line
      const userId = this.authData.userId;
      axios.get(`/users/getUser/${userId}.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`, this.user)
        .then((response) => {
          this.user = response.data.user;
        });
    },
    saveUser(result) {
      this.user.files = result;
      axios.post(`/users/editAccount.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`, this.user)
        .then((response) => {
          if (response.data.response === true) {
            this.message = 'Your profile has been updated successfully';
            this.router.push('/list-users/');
          } else {
            this.message = 'Your profile could not be updated - please check the details';
          }
        });
    },
    clearMessage() {
      this.message = '';
    },
  },
  mounted() {
    this.getUser();
    Uppy({
      debug: true,
      autoProceed: false,
      restrictions: {
        maxFileSize: (1000000 * 1000),
        maxNumberOfFiles: 3,
        minNumberOfFiles: 0,
      },
    })
      .use(UppyForm, {
        target: '.user-form',
        multipleResults: true,
        triggerUploadOnSubmit: true,
        submitOnSuccess: false,
      })
      .use(FileInput, {
        id: 'FileInputOne',
        target: '.file-one',
        pretty: true,
        inputName: 'user-file-one',
      })
      .use(FileInput, {
        id: 'FileInputTwo',
        target: '.file-two',
        pretty: true,
        inputName: 'user-file-two',
      })
      .use(FileInput, {
        id: 'FileInputThree',
        target: '.file-three',
        pretty: true,
        inputName: 'user-file-three',
      })
      .on('file-added', (res) => {
        if (this.filenameOne === '') {
          this.filenameOne = res.name;
        } else if (this.filenameOne !== '' && this.filenameTwo === '') {
          this.filenameTwo = res.name;
        } else {
          this.filenameThree = res.name;
        }
      })
      .use(ProgressBar, {
        target: '.UppyProgressBar',
        hideAfterFinish: false,
      })
      .use(Transloadit, {
        service: 'https://api2.transloadit.com',
        params: {
          auth: { key: 'db6a1d40944f11e5a0a165fb32f6e49b' },
          template_id: 'ad27d2c2cad8454fba2791c04c58c00e',
        },
        waitForEncoding: true,
        waitForMetadata: false,
        importFromUploadURLs: false,
        alwaysRunAssembly: false,
        signature: null,
      })
      .on('complete', (result) => {
        this.saveUser(result);
      });
  },
};
</script>
